import React from "react";
import { Admin, Resource } from "react-admin";
import dataProvider from "./dataProvider";
import { authProvider } from "./AuthProvider";
import { TopicList } from "./Resources/TopicList";
import { TopicEdit } from "./Edits/TopicEdit";
import { QuestionList } from "./Resources/QuestionList";
import { QuestionEdit } from "./Edits/QuestionEdit";
import { QuestionCreate } from "./Create/QuestionCreate";
import RemotionsLoginPage from "./Login/RemotionsLoginPage";

const App = () => (
  <Admin
    dataProvider={dataProvider}
    loginPage={RemotionsLoginPage}
    authProvider={authProvider}
  >
    <Resource
      name="questions"
      list={QuestionList}
      edit={QuestionEdit}
      create={QuestionCreate}
      options={{ label: "Fragen" }}
    />
    <Resource
      name="topics"
      list={TopicList}
      edit={TopicEdit}
      options={{ label: "Themengebiete" }}
    />
  </Admin>
);

export default App;
