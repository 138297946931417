import { fetchUtils } from "react-admin";
import { stringify } from "query-string";

const { REACT_APP_BACKEND_URL } = process.env;
const apiUrl = REACT_APP_BACKEND_URL;

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }

  const token = window.localStorage.getItem("token");

  options.headers.set("Authorization", `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

export default {
  getList: (resource, params) => {
    const query = {
      _start: (params.pagination.page - 1) * params.pagination.perPage,
      _end: params.pagination.page * params.pagination.perPage,
      search: params.filter.search ? params.filter.search : undefined,
      topic: params.filter.topic ? params.filter.topic : undefined,
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    switch (resource) {
      case "topics":
        return httpClient(url).then(({ headers, json }) => ({
          data: [...json.created, ...json.updated],
          total: parseInt(headers.get("X-Total-Count"), 10),
        }));

      default:
        return httpClient(url).then(({ headers, json }) => ({
          data: json,
          total: parseInt(headers.get("X-Total-Count"), 10),
        }));
    }
  },

  getOne: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
      data: json,
    })),

  getMany: (resource, params) => {
    const query = {
      _start: params.pagination
        ? (params.pagination.page - 1) * params.pagination.perPage
        : undefined,
      _end: params.pagination
        ? params.pagination.page * params.pagination.perPage
        : undefined,
      search:
        params.filter && params.filter.search
          ? params.filter.search
          : undefined,
    };

    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    switch (resource) {
      case "topics":
        return httpClient(url).then(({ headers, json }) => ({
          data: [...json.created, ...json.updated],
          total: parseInt(headers.get("X-Total-Count"), 10),
        }));

      default:
        return httpClient(url).then(({ headers, json }) => ({
          data: json,
          total: parseInt(headers.get("X-Total-Count"), 10),
        }));
    }
  },

  getManyReference: (resource, params) => {
    const url = `${apiUrl}/${resource}?${stringify(null)}`;

    switch (resource) {
      case "topics":
        return httpClient(url).then(({ headers, json }) => ({
          data: [...json.created, ...json.updated],
          total: parseInt(headers.get("content-range").split("/").pop(), 10),
        }));

      default:
        return httpClient(url).then(({ headers, json }) => ({
          data: json,
          total: parseInt(headers.get("content-range").split("/").pop(), 10),
        }));
    }
  },

  update: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json })),

  updateMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  create: (resource, params) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: "POST",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    })),

  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "DELETE",
    }).then(({ json }) => ({ data: json })),

  deleteMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "DELETE",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },
};
