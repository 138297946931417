import * as React from "react";
import { List, Datagrid, TextField } from "react-admin";
import { ColorField } from "react-admin-color-input";

export const TopicList = (props) => {
  return (
    <List {...props} exporter={false}>
      <Datagrid rowClick="edit">
        <TextField source="name" label="Themengebiet" />
        <TextField source="questionCount" label="Anzahl Fragen" />
        <ColorField source="color" lable="Farbe" />
      </Datagrid>
    </List>
  );
};
