import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  Filter,
  TextInput,
  SelectInput,
  ReferenceInput,
} from "react-admin";

const QuestionFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Frage suchen " source="search" alwaysOn />
    <ReferenceInput label="Themengebiet" source="topic" reference="topics">
      <SelectInput source="id" />
    </ReferenceInput>
  </Filter>
);

export const QuestionList = (props) => (
  <List
    {...props}
    title="Alle Fragen"
    filters={<QuestionFilter />}
    exporter={false}
  >
    <Datagrid rowClick="edit">
      <TextField source="value" label="Frage" />
      <ReferenceField
        source="topic"
        reference="topics"
        label="Verknüpftes Themengebiet"
      >
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
);
