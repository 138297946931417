import React from "react";
import { Edit, SimpleForm, TextInput } from "react-admin";
import { ColorInput } from "react-admin-color-input";

export const TopicEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" label="Themengebiet" />
      <ColorInput source="color" lable="Farbe" />
    </SimpleForm>
  </Edit>
);
