const { REACT_APP_BACKEND_URL } = process.env;

export const authProvider = {
  login: async ({ username, password, tenant }) => {
    const response = await window.fetch(
      REACT_APP_BACKEND_URL + "/users/token",
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: username, password, tenant }),
      }
    );

    if (response.status < 200 || response.status >= 300) {
      throw new Error(response.statusText);
    }

    try {
      let token = await response.text();
      token = token.replace(/"/g, "");
      window.localStorage.setItem("token", token);
    } catch (err) {
      throw new Error(err);
    }
  },
  logout: (params) => {
    window.localStorage.removeItem("token");
    return Promise.resolve();
  },
  checkAuth: (params) =>
    window.localStorage.getItem("token") ? Promise.resolve() : Promise.reject(),
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("token");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getPermissions: (params) => Promise.resolve(),
};
