import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  BooleanInput,
  ReferenceInput,
  SelectInput,
} from "react-admin";

export const QuestionEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="value" label="Frage" />
      <ReferenceInput label="Themengebiet" source="topic" reference="topics">
        <SelectInput source="id" />
      </ReferenceInput>
      <ArrayInput source="answers" label="Antworten">
        <SimpleFormIterator>
          <TextInput source="value" label="Antworttext" />
          <BooleanInput source="isCorrect" label="Ist korrekte Antwort" />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
);
